
import * as React from "react";
import * as ReactDOM from "react-dom";
//import 'jquery';
//import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Url } from "../avernus/url/source/Url";
import { Api } from "./lib/Api";
import { Suspense } from "react";
import Index from "./components/Index";
import View from "./components/View";
import Reports from "./components/Reports";
import NewReportDashboard from "./components/NewReportDashboard";
import NewReport from "./components/NewReport";
import ReportsMap from "./components/ReportsMap";
import Admin from "./admin/Admin";
import AdminEmploiler from "./admin/AdminEmploiler";
import Settings from "./components/Settings";
import Login from "./components/Login";

interface IAppMainProps {
}

interface IAppMainState {
    currentUrl: string
    data      : any
}

interface ICheckTokenFunction{
    (redirectUrl:string):void
}


export default class AppMain extends React.Component<IAppMainProps, IAppMainState>{

    private firstUrl:string;
    private mounted:boolean;

  	constructor(props:IAppMainProps){
        super(props);
        this.mounted = false;
        this.state = {
            currentUrl: '',
            data: null
        };
        this.firstUrl = ('/'+Url.getUrl())==='/'?'/index':('/'+Url.getUrl());
        if ( this.firstUrl === '/login' ) {
            this.firstUrl = '/index';
        }
        this.checkToken();
    }

    checkToken(cb:ICheckTokenFunction=null, redirectUrl?:string):void {
        Api.check((valid)=>{
            if ( valid ) {
                if ( cb !== null) {
                    cb(redirectUrl);
                }else{
                    this.setUrl(this.firstUrl);
                }
            }else{
                if ( cb !== null) {
                    cb('/login');
                }else{
                    this.setUrl('/login');
                }
            }
        });
    }

    render() {
        this.mounted = true;
        let permission = Api.getPermission();
        if ( Url.isUrl(/admin$/) ) {
            this.setUrl('/admin/1');
        }
        return (
            <>
                { Url.isUrl(/admin\/emploiler/) && permission==='admin'?<AdminEmploiler setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:Url.isUrl(/admin/) && permission.indexOf('admin')>-1?
                    <Admin key={'admin_'+Url.getUrlPart(1)} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } categoryId={parseInt(Url.getUrlPart(1))} />:'' }

                { Url.isUrl(/settings/)?<Settings setUrl={
                        (url, data) =>{
                            this.setUrl(url, data);
                        }
                    } />:'' }

                { Url.isUrl(/index/)?<Index setUrl={
                        (url, data) =>{
                            this.setUrl(url, data);
                        }
                    } />:'' }

                { Url.isUrl(/view\/[0-9]+/)?<View id={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }

                { Url.isUrl(/new\/[0-9]+/)?<NewReportDashboard typeCategoryId={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }
                { Url.isUrl(/new-without-image\/[0-9]+/)?<NewReport withImage={false} typeCategoryId={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }
                { Url.isUrl(/new-with-image\/[0-9]+/)?<NewReport withImage={true} typeCategoryId={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }

                { Url.isUrl(/reports\/[0-9]+/)?<Reports categoryId={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }
                { Url.isUrl(/map\/[0-9]+/)?<ReportsMap categoryId={parseInt(Url.getUrlPart(1))} setUrl={
                        (url) =>{
                            this.setUrl(url);
                        }
                    } />:'' }

                { Url.isUrl(/login/)?
                    <Login setUrl={
                        () =>{
                            let permission = Api.getPermission();
                            if ( permission.indexOf('admin') > -1 && this.firstUrl.indexOf('admin') === -1) {
                                // when admin but not admin url
                                this.firstUrl = '/admin';
                            }else  if ( permission.indexOf('admin') == -1 && this.firstUrl.indexOf('admin') > -1 ) {
                                // when user, but admin url
                                this.firstUrl = '/index';
                            }
                            this.setUrl(this.firstUrl);
                        }
                    } />:'' }

			</>
        );
    }
    setUrl(url:string, data:any=undefined) {
        if ( this.state.currentUrl !== url ) {
            // this.checkToken((redirectUrl)=>{
                Url.setUrl(url);
                if ( this.mounted === true ) {
                    this.setState({
                        currentUrl: url,
                        data: data
                    });
                }else{
                    setTimeout(() => {
                        this.setState({
                            currentUrl: url,
                            data: data
                        });
                    }, 200);
                }

            // }, url);
        }
    }
}
export class InitAppMain {
    constructor() {
        var div: HTMLDivElement = document.getElementById('app') as HTMLDivElement;
        if (div !== null) {
            ReactDOM.unmountComponentAtNode(div);
            ReactDOM.render(<AppMain />, div);
        }
    }
}
