import { InitAppMain } from "./AppMain";

module TSZOL {
    "use strict";
    export module Application {

        export function initialize() {
            if (  (window as any).notAndroidApp !== undefined ) {
                new InitAppMain();
            }else{
                document.addEventListener('deviceready', () => {
                    new InitAppMain();
                }, false);
            }
        }
    }

    window.onload = () => {
        Application.initialize();
    }
}
