import Ajax from "../../avernus/ajax/Ajax";
import { TStorage } from "../../avernus/tstorage/TStorage";
import Config from "../Config";

interface ICheckCb{
    (valid:boolean):void
}

interface ICheckResult{
    tokenError:boolean
    api_token:string
    permission:string
}


export class Api{
    static token: string;
    static permission: string;


    public static cleanStorage(){
        this.setPermission(undefined);
        this.setToken(undefined);
    }


    public static setPermission(permission: string):void {
        let Storage = new TStorage();
        Storage.set('api_permission', permission);
        this.permission = permission;
    }

    public static getPermission():string{
        let Storage      = new TStorage();
        let permission   = Storage.get('api_permission', undefined);
        this.permission  = permission;
        return this.permission;
    }

    public static setToken(api_token: string):void {
       let Storage = new TStorage();
       Storage.set('api_token', api_token);
       this.token = api_token;
    }

    public static getToken():string{
        let Storage = new TStorage();
        let token   = Storage.get('api_token', undefined);
        this.token = token;
        return this.token;
    }

    public static getServerRouteUrl():string{
        return Config.getApiUrl();
    }

    private static getServerUrl():string{
       return this.getServerRouteUrl()+'/api';
    }

    public static check(cb:ICheckCb){
        if ( this.getToken() === undefined ) {
            cb(false);
            return;
        }

        this.call('/check', {}, (ret:ICheckResult) =>{
            let tokenError:boolean = ret.tokenError===undefined?true:false;
            if ( tokenError === false ) {
                // NOTE: Később
                // this.setToken(ret.api_token);
                this.setPermission(ret.permission);
            }
            cb(tokenError);
        });
    }

    public static call(url:string, data:any, cb:Function) {
        if ( this.getToken() !== undefined ) {
            data.api_token = this.getToken();
        }
        new Ajax(this.getServerUrl()+url, 'post', data, (ret:any) =>{
            cb(ret);
        });
    }
}

