
import * as React from "react";
import Footer from "./Footer";
import ReportsHeader from "./ReportsHeader";
import { Api } from "../lib/Api";

interface ISettingsProps {
    setUrl:any
}

interface ISaveUser{
    success: string
    error  : string
}

interface IUser{
    password:string
    passwordm:string
}

interface ISettingsState {
    success       : string
    error         : string
    form          : IUser
    enabledSaveBtn: boolean
    loading: boolean
}

export default class Settings extends React.Component<ISettingsProps, ISettingsState>{

  	constructor(props:ISettingsProps){
        super(props);
        this.state = {
            form: {
                password : '',
                passwordm: ''
            },
            success       : null,
            error         : null,
            enabledSaveBtn: false,
            loading       : false
        }
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form = this.state.form;
        form[type] = evt.target.value;
        let error = null;
        let enabledSaveBtn = true;
        if ( form.password.toString().trim() === '' ) {
            enabledSaveBtn = false;
        }
        if ( form.passwordm.toString().trim() === '' ) {
            enabledSaveBtn = false;
        }
        if ( form.password.toString().trim() !== '' && form.passwordm.toString().trim() !== '' && form.password !== form.passwordm) {
            error = 'A két jelszó nem egyezik!';
            enabledSaveBtn = false;
        }
        this.setState({
            form            : form,
            success         : null,
            error           : error,
            enabledSaveBtn  : enabledSaveBtn
        });
    }

    private submitHandler(evt): void {
        evt.preventDefault();
        this.setState({
             loading: true
        }, () =>{
            Api.call('/settings/save', {
                password: this.state.form.password
            }, (result:ISaveUser)=>{
            this.setState({
                success: result.success,
                error: result.error
            });
        });
        });

    }



    render() {
        return (
            <>
                <ReportsHeader setUrl={this.props.setUrl} name="Beállítások"></ReportsHeader>
                <section className="shadow" id="settings_section">
                    <div className="container shadow">
                        <div className="row form_row">
                            <div className="col-lg-6 offset-lg-3">
                                <form onSubmit={ e => this.submitHandler(e) }
                                >
                                    <div className="form-group">
                                        <div className="input-group two_input_50_50">
                                            <div className="input-group-prepend">
                                                <span className="d-inline-block input-group-text">Jelszó:</span>
                                            </div>
                                            <input type="password" className="form-control" name="password" required={true} value={ this.state.form.password } onChange={ (e) => this.changeInputHandler(e, 'password') } />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group two_input_50_50">
                                            <div className="input-group-prepend">
                                                <span className="d-inline-block input-group-text">Jelszó újra:</span>
                                            </div>
                                            <input type="password" className="form-control" name="passwordm" required={true} value={ this.state.form.passwordm } onChange={ (e) => this.changeInputHandler(e, 'passwordm') } />

                                        </div>
                                    </div>
                                    { this.state.error !== null?<div className="alert alert-danger">
                                            { this.state.error }
                                    </div>:''}
                                    { this.state.success !== null?<div className="alert alert-success">
                                            { this.state.success }
                                    </div>:''
                                    }
                                    <div className="form-group d-flex justify-content-end button-form-group">
                                        <button className="btn btn-primary btn-sm text-center d-flex justify-content-center" type="submit" disabled={!this.state.enabledSaveBtn}>MENTÉS</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer setUrl={this.props.setUrl}></Footer>
            </>
        );
    }
}
