
import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import TypeCategories from "./TypeCategories";

interface IIndexProps {
    setUrl:any
}

interface IIndexState {
}

export default class Index extends React.Component<IIndexProps, IIndexState>{

  	constructor(props:IIndexProps){
        super(props);
    }

    render() {
        return (
            <>
               <Header setUrl={this.props.setUrl}></Header>
               <TypeCategories url="/reports/" setUrl={this.props.setUrl}></TypeCategories>
               <Footer setUrl={this.props.setUrl}></Footer>
			</>
        );
    }
}
