
import * as React from "react";
import Footer from "./Footer";
import ReportsHeader from "./ReportsHeader";
import { ITypeCategory } from "../interfaces/IType";
import { IReportItem } from "../interfaces/IReport";
import { Api } from "../lib/Api";
import DateTool from "../../avernus/datetool/source/DateTool";
interface IReportsProps {
    setUrl    : any
    categoryId: number
}

interface IReportsState extends IgetReportsResult {
    loading     : boolean
}
interface IgetReportsResult{
    items       : Array<IReportItem>
    typeCategory: ITypeCategory
    error:string
}

export default class Reports extends React.Component<IReportsProps, IReportsState>{

  	constructor(props:IReportsProps){
        super(props);
        this.state = {
            typeCategory : null,
            items        : [],
            loading      : true,
            error        : null
        }
        this.init();
    }

    init() {
        Api.call('/getReports', { typeCategoryId: this.props.categoryId}, (result:IgetReportsResult)=>{
            if ( result.items !== undefined ) {
                this.setState({
                    items          : result.items,
                    typeCategory   : result.typeCategory,
                    error          : result.error!==undefined?result.error:null,
                    loading        : false
                });
            }
        });
    }
    render() {
        document.body.classList.remove('hompe_page');
        return (
            <>
               { this.state.loading === false?<>
                    <ReportsHeader setUrl={this.props.setUrl} name={this.state.typeCategory.name} categoryId={this.props.categoryId} img={this.state.typeCategory.img}></ReportsHeader>
                    { this.state.error !== null?<div className="alert alert-danger">
                            { this.state.error }
                    </div>:
                        <section className="fluid_center">
                            <div className="container-fluid container-fluid-no-padding">
                                <div className="row no-gutters top_list_menubar">
                                    <div className="col-6">
                                        <button className="btn btn-secondary btn-sm d-flex align-items-center" id="map_btn" type="button" onClick={ e => {
                                                this.props.setUrl('/map/'+this.props.categoryId);
                                            }}>
                                            <img src="../assets/img/Image%2027.png" /> térkép nézet
                                        </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <button className="btn btn-primary btn-sm text-center d-flex align-items-center" id="new_item_btn" type="button" onClick={ e => {
                                                this.props.setUrl('/new/'+this.props.categoryId);
                                            }}>
                                            <img src={"../assets" + this.state.typeCategory.img} style={{ filter: "invert(100%)" }} /> új felvitele
                                        </button>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <div className="table-responsive table-borderless">
                                            <table className="table table-striped table-bordered">
                                                <thead style={{background: 'rgb(63,137,127)', color: 'white'}}>
                                                    <tr>
                                                        <th className="text-center">típus<strong>:</strong></th>
                                                        <th style={{width: '60px'}}><strong>dátum:</strong></th>
                                                        <th><strong>cím:</strong><br/></th>
                                                        <th style={{width: '50px'}}><br/></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.state.items.length===0?<tr>
                                                        <td colSpan={4}>
                                                            Nincs találat!
                                                        </td>
                                                    </tr>:'' }
                                                    {
                                                        this.state.items.map((item, index) => {
                                                            return <tr key={'tr_'+index}>
                                                                <td className="text-center">{ item.typeName }</td>
                                                                <td>{ DateTool.getDateHu(item.created_at) }</td>
                                                                <td>{ item.zip } { item.city }, { item.address }</td>
                                                                <td className="text-right">
                                                                    <a className="btn btn-link btn-sm" role="button" onClick={ e => {
                                                                        this.props.setUrl('/view/'+item.id);
                                                                    }}>
                                                                        <i className="icon ion-eye"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                     }
                    <Footer setUrl={this.props.setUrl}></Footer>
               </>:'Betöltés...' }


			</>
        );
    }
}
