
import * as React from "react";
import { TStorage } from "../../avernus/tstorage/TStorage";

interface IReportsFooterProps {
    setUrl: any
    name  : string
    categoryId   : number
}

interface IReportsFooterState {
}

export default class ReportsFooter extends React.Component<IReportsFooterProps, IReportsFooterState>{

  	constructor(props:IReportsFooterProps){
        super(props);
    }

    render() {
        var name = new TStorage().get('name', '');
        return (
            <div className="container">
                <div className="row footer">
                    <div className="col-8 col-sm-10 d-flex flex-grow-0 justify-content-start align-items-center align-self-center login_box">
                        <p>Bejelentkezve: <strong>{ name }</strong></p>
                    </div>
                    <div className="col-4 col-sm-2 d-flex flex-grow-0 justify-content-end align-items-center">
                        <a className="text-light" onClick={ e => {
                                                this.props.setUrl('/reports/'+this.props.categoryId);
                                            }}>
                                            { this.props.name } listája >>
                                            </a>
                        </div>
                </div>
            </div>
        );
    }
}
