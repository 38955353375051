interface IImageResizerCb{
    (blob:string):void
}

export class ImageResizer {
    private canvas:HTMLCanvasElement;
    private crop:string;
    private exifData:any;
    constructor(imagePath:string, w: number, h: number, targetExt:string, cb:IImageResizerCb, modify:string='box', exifData=null) {
        this.crop = undefined;
        this.exifData = exifData;
        this.createCanvas();
        this.orientation(imagePath, targetExt, (src) =>{
            this.resize(src, w, h, targetExt, cb, modify);
        })
    }

    private createCanvas():void{
        if ( document.getElementById('resize_canvas') !== null  ) {
            this.canvas = document.getElementById('resize_canvas') as HTMLCanvasElement;
        }else{
            this.canvas = document.createElement('canvas');
            this.canvas.width = 800;
            this.canvas.height = 600;
            this.canvas.id = 'resize_canvas';
            this.canvas.style.display='none';
            document.body.appendChild(this.canvas);
        }
    }

    protected resize(src: string, w: number, h: number, targetExt:string, cb: IImageResizerCb, modify:string='box') {
        var img = new Image();
        img.onload = () => {
            if ( this.canvas === undefined) {
                return;
            }
            var x = 0;
            var y = 0;
            var canvas_width:number = img.width;
            var canvas_height:number = img.height;
            //crop center
            if (w !== null && h !== null) {
                if ( this.crop !== undefined ) {
                    if (w === h) { //négyzetes kép esetén, ez egy speckó
                        if (this.crop === 'center') {

                            if (img.height < img.width) {
                                if (img.height > h) {
                                    img.width *= h / img.height;
                                    img.height = h;
                                }
                            } else {
                                if (img.width > w) {
                                    img.height *= w / img.width;
                                    img.width = w;
                                }
                            }
                            x = (w - img.width) / 2;
                            y = (h - img.height) / 2;

                            canvas_width  = w;
                            canvas_height = h;
                        }else if (this.crop === 'top') {

                            if (img.height < img.width) {
                                if (img.height > h) {
                                    img.width *= h / img.height;
                                    img.height = h;
                                }
                            } else {
                                if (img.width > w) {
                                    img.height *= w / img.width;
                                    img.width = w;
                                }
                            }
                            x = (w - img.width) / 2;
                            y = 0;

                            canvas_width  = w;
                            canvas_height = h;
                        }
                    }
                } else {
                    // box
                    if (modify === 'box') {
                        //sima meretezes start
                        if (h > 0 && img.height > h) {
                            img.width *= h / img.height;
                            img.height = h;
                        }
                        if (w > 0 && img.width > w) {
                            img.height *= w / img.width;
                            img.width = w;
                        }
                        canvas_width = img.width;
                        canvas_height = img.height;
                        //sima meretezes end
                    } else if (modify === 'crop') {
						if (img.height < img.width) {
							if (img.height > h) {
								img.width *= h / img.height;
								img.height = h;
							}
						} else {
							if (img.width > w) {
								img.height *= w / img.width;
								img.width = w;
							}
						}
						x = (w - img.width) / 2;
						y = 0;

						canvas_width = w;
						canvas_height = h;
                    } else if (modify === 'crop_top_height') {

						if (img.width > w) {
							var new_height = img.height *= w / img.width;
							if (new_height >= h) {
								img.height = new_height;
								img.width = w;
							}
						}

						x = (w - img.width) / 2;
						y = 0;

						canvas_width = w;
						canvas_height = h;
                    }
                }
            } else {
                //original size
                canvas_width = img.width;
                canvas_height = img.height;
            }

            var ctx = this.canvas.getContext("2d");
            //ctx.ImageSmoothingEnabled = false;
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            this.canvas.width = canvas_width;
            this.canvas.height = canvas_height;
			ctx.drawImage(img, x, y, img.width, img.height);
            //watermark place
            // if (watermark_img !== null) {
            //     if (first !== undefined && first === true) {
            //         var wx = img.width - watermark_img.width;
            //         var wy = img.height - watermark_img.height;
            //         ctx.drawImage(watermark_img, wx, wy, watermark_img.width, watermark_img.height);
            //     }
            // }

            if (cb !== undefined) {
                cb(this.canvas.toDataURL("image/"+targetExt));
            }
        };
        img.src = src;
    }

    private orientation(path, targetExt, cb) {
        if (  this.exifData.Orientation !== undefined ) {
            var img = new Image();
            img.onload = () => {
                if ( this.canvas === undefined) {
                    return;
                }
                // Set variables
                var ctx = this.canvas.getContext("2d");
                var exifOrientation = null;
                var width = img.width,
                    height = img.height;


                exifOrientation = parseInt(this.exifData.Orientation);

                // set proper canvas dimensions before transform & export
                if ( [5, 6, 7, 8].indexOf(exifOrientation) > -1) {
                    this.canvas.width = height;
                    this.canvas.height = width;
                } else {
                    this.canvas.width = width;
                    this.canvas.height = height;
                }

                // transform context before drawing image
                switch (exifOrientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
	                break;
	            case 7:
	                ctx.transform(0, -1, -1, 0, height, width);
	                break;
	            case 8:
	                ctx.transform(0, -1, 1, 0, 0, width);
	                break;
	            default:
	                ctx.transform(1, 0, 0, 1, 0, 0);
	            }
                // Draw img into canvas
                ctx.drawImage(img, 0, 0, width, height);
                cb(this.canvas.toDataURL("image/"+targetExt));
            };
            img.src = path;
        }else{
             cb(path);
        }
    }
}
