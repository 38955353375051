export default class Config{
    public static getAPIKey():string{
        return 'AIzaSyB-IiveyvmtJgR6BjxLQHbejyIWLJgTvRs';
    }

    public static getApiUrl():string{
        if ( window.location.href.indexOf('tszol.localhost')> -1  ) {
            return 'http://tszol.localhost';
        }
        if ( window.location.href.indexOf('localhost:800')> -1  ) {
            return 'http://localhost:8008';
        }
        // return 'https://api.tszol.ecom-hosting2.com';
        return 'https://api.tszol.varosapp.com';
    }
}
