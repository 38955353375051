
import * as React from "react";

interface IHeaderProps {
    setUrl:any
}

interface IHeaderState {
}

export default class Header extends React.Component<IHeaderProps, IHeaderState>{

  	constructor(props:IHeaderProps){
        super(props);
    }

    render() {
        return (
            <div className="container">
                <div className="row d-flex d-lg-flex">
                    <div className="col text-center" onClick={ e => this.props.setUrl('/index') }
                    ><img className="logo" src="/assets/img/logo.png" /></div>
                </div>
            </div>
        );
    }
}
