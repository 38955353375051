interface IAjaxData{
	data: any;
	url: string;
	uri_text: string;
}
export default class Ajax{
	constructor(url:string, method:string, data:any, cb:Function, blobMode?:boolean, errorHanlder?:Function){
		var blob:boolean = false;
		if ( blobMode !== undefined ) {
			blob = blobMode;
		}
		this.send(url, method, data, cb, blob, errorHanlder);
	}

    private createFormData(object: Object, form?: FormData, namespace?: string): FormData {
		const formData = form || new FormData();
		for (let property in object) {
			if (!object.hasOwnProperty(property)) {
				continue;
			}
			const formKey = namespace ? `${namespace}[${property}]` : property;
			if (object[property] instanceof Date) {
				formData.append(formKey, object[property].toISOString());
			} else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
				this.createFormData(object[property], formData, formKey);
			} else {
				formData.append(formKey, object[property]);
			}
		}
		return formData;
	}

	private send(url:string, method:string, data:any, cb:Function, blob:boolean, errorHanlder?:Function):void{
        var bodyData = this.createFormData(data);
        fetch(url, {
            method: method,
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                // "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
                // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            // body: JSON.stringify(t.data), // body data type must match "Content-Type" header
            body: bodyData, // body data type must match "Content-Type" header
        })
        // .then(response => response.json())
        .then(response => response.text())
        .then(_data => {
            if ( blob === true ) {
                cb(_data);
            }else{
                var body_first_char = _data.toString().replace(/\n/,' ').replace(/\n/,' ').trim().substr(0,1);
                if ( body_first_char === '{' || body_first_char === '[' ) {
                    // json
                    cb(JSON.parse(_data));
                }else{
                    // html or text
                    cb(_data);
                }
            }
            
        })
        .catch((error) => {
             if ( errorHanlder !== undefined  ) {
                errorHanlder();
            }else{
                console.error('XHR request faild!', error);
            }
        });
	}
}
