
import * as React from "react";
import * as EXIF from 'exif-js';
import { Api } from "../lib/Api";
import { IgetTypeCategoryResult } from "./NewReportDashboard";
import ReportsHeader from "./ReportsHeader";
import Maps from "../../avernus/googlemaps/source/Maps";
import { IReportItem } from "../interfaces/IReport";
import { ReadInputFile } from "../../avernus/file/source/ReadInputFile";
import { ImageResizer } from "../../avernus/imageresizer/source/ImageResizer";
import { IType } from "../interfaces/IType";
import ReportsFooter from "./ReportsFooter";
import Config from "../Config";

interface INewReportProps {
    setUrl:any
    typeCategoryId:number
    withImage:boolean
}

interface INewReportState extends IgetTypeCategoryResult {
    loading        : boolean
    saveing        : boolean
    form           : IReportItem
    uploaded_image : string
    uploaded_image2: string
    types          : Array<IType>
    success        : string
}

interface IgetTypeCategoryResultWithTypes extends IgetTypeCategoryResult{
    types:Array<IType>
}

interface IAddReportResult{
    error  : string
    success: string
}

export default class NewReport extends React.Component<INewReportProps, INewReportState>{
    apiKey       : string;
    nearlyAddress: any;
    lastUploadValue:any;

  	constructor(props:INewReportProps){
        super(props);
        this.apiKey          = Config.getAPIKey();;
        this.nearlyAddress   = null;
        this.lastUploadValue = null;
        this.state = {
            typeCategory   : null,
            loading        : true,
            saveing        : false,
            error          : null,
            success        : null,
            form           : this.createEmptyForm(),
            uploaded_image : this.getSampleImageValue(),
            uploaded_image2: null,
            types          : []
        }
        this.init();
    }

    private getSampleImageValue(): string {
        return '../assets/img/pic_Image%2020@2x.png';
    }

    private createEmptyForm(): IReportItem {
        return {
            id        : 0,
            typeId    : 0,
            longitude : 0,
            latitude  : 0,
            zip       : '',
            city      : '',
            address   : '',
            comment   : '',
            img       : '',
            img2      : null,
            userId    : null,
            status    : 'new',
        };
    }

    init() {
        Api.call('/getTypeCategory', { typeCategoryId: this.props.typeCategoryId}, (result:IgetTypeCategoryResultWithTypes)=>{
            let error = result.error!==undefined?result.error:null;
            if ( error === null ) {
                //get positions
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position)=>{
                        let form       = this.state.form;
                        form.longitude = position.coords.longitude;
                        form.latitude  = position.coords.latitude;
                        this.setState({
                             form: form
                        }, () =>{
                            if ( this.props.withImage === false ) {
                                this.getNearlyCity();
                            }
                        });
                    });
                }
            }
            let form       = this.state.form;
            if ( result.types.length === 1 ) {
                form.typeId = result.types[0].id;
            }
            this.setState({
                typeCategory   : result.typeCategory,
                types          : result.types,
                form            : form,
                error          : error,
                loading        : false
            });
        });
    }

    private getNearlyCity() {
        if ( this.nearlyAddress === null ) {
            Api.call('/getNerlyCity', {
                latitude:  this.state.form.latitude,
                longitude: this.state.form.longitude,
                apiKey: this.apiKey
            }, (result)=>{
                this.nearlyAddress = result;
                this.setNerylAddress();
            });
        }
        this.setNerylAddress();
    }

    private setNerylAddress(){
        if ( this.nearlyAddress !== null ) {
            if ( this.nearlyAddress.results !== undefined  && this.nearlyAddress.results.length > 0  && this.nearlyAddress.results[0].address_components !== undefined ) {
                var address_components = this.nearlyAddress.results[0].address_components;
                let form = this.state.form;
                form.address = '';
                for (var key in address_components) {
                    var item = address_components[key];
                    if ( item.types.indexOf('street_number') === 0) {
                        form.address = ' ' + item.short_name;
                    }
                    if ( item.types.indexOf('route') === 0) {
                        form.address =  item.short_name + form.address;
                    }
                    if ( item.types.indexOf('locality') === 0) {
                        form.city =  item.short_name;
                    }
                    if ( item.types.indexOf('postal_town') === 0) {
                        form.city =  item.short_name;
                    }
                    if ( item.types.indexOf('postal_code') === 0) {
                        form.zip =  item.short_name;
                    }
                    this.setState({
                         form: form
                    });
                }

            }
        }
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form = this.state.form;
        form[type] = evt.target.value;
        this.setState({
            form: form
        });
    }


    private submitHandler(evt): void {
        evt.preventDefault();
        this.setState({
             saveing: true
        }, () =>{
            var saveData = this.state.form;
            if ( this.props.withImage === true ) {
                saveData.img  =  this.state.uploaded_image;
                saveData.img2 =  this.state.uploaded_image2;
            }else{
                saveData.img = null;
                saveData.img2 = null;
            }
            Api.call('/addReport', saveData, (result:IAddReportResult) =>{
                if ( result.error !== undefined) {
                    this.setState({
                        error: result.error,
                        saveing : false
                    });
                }
                if ( result.success !== undefined) {
                    this.setState({
                        success: result.success,
                        saveing : false
                    }, () =>{
                        setTimeout(() => {
                            this.props.setUrl('/reports/'+this.props.typeCategoryId);
                        }, 2000);
                    });
                }
            });
        });


    }



    render() {
        document.body.classList.remove('hompe_page');
        document.body.classList.add('footer_without_margin');
        return (
             <>
               { this.state.loading === false?<>
                    <ReportsHeader setUrl={this.props.setUrl}  categoryId={this.props.typeCategoryId} name={this.state.typeCategory.name} img={this.state.typeCategory.img}></ReportsHeader>
                    { this.state.error !== null?<div className="alert alert-danger">
                            { this.state.error }
                    </div>:
                        <>
                            <section className="shadow" id="create_with_photo">
                                <div className="container shadow">
                                    { this.props.withImage === true?<>
                                        <div className="row image_row">
                                            <div className="col text-center">
                                                <img id="uploaded_image" src={this.state.uploaded_image} style={{ maxWidth: '100%', width:'750px', maxHeight:'422px' }} />
                                                <button className="btn btn-primary btn-sm d-flex justify-content-center align-items-center" type="button" onClick={ e => {
                                                    e.preventDefault();
                                                    document.getElementById('image_uploader').click();
                                                } }
                                                >
                                                    <i className="icon ion-camera"></i> új kép készítése
                                                </button>
                                            </div>
                                        </div>
                                        { this.state.uploaded_image !== this.getSampleImageValue()?<div className="row image_row" style={{marginTop: '25px'}}>
                                            <div className="col text-center">
                                                <img id="uploaded_image2" src={this.state.uploaded_image2} style={{ maxWidth: '100%', width:'750px', maxHeight:'422px' }} />
                                                <button className="btn btn-primary btn-sm d-flex justify-content-center align-items-center" type="button" onClick={ e => {
                                                    e.preventDefault();
                                                    document.getElementById('image_uploader2').click();
                                                } }
                                                >
                                                    <i className="icon ion-camera"></i> Kiegészétő kép feltöltése
                                                </button>
                                            </div>
                                        </div>:'' }


                                    </>:'' }
                                    <div className="row form_row">
                                        <div className="col">
                                            <form onSubmit={ e => this.submitHandler(e) }
                                            >
                                                { this.props.withImage === true?<>
                                                   <input type="file" id="image_uploader" name="file" required={true} style={{ opacity:0, width: '1px', height: '1px' }} onChange={ e => {
                                                        e.preventDefault();
                                                        new ReadInputFile('image_uploader', (percent, content)=>{
                                                        if ( content !== '' ) {
                                                            let form = this.state.form;
                                                            form.img = 'filed';
                                                            this.setState({
                                                                uploaded_image: (content).toString(),
                                                                form: form
                                                            }, ()=>{
                                                                this.gpsFromImage();
                                                            });

                                                        }
                                                    }, 'url');
                                                    } }
                                                    />
                                                   <input type="file" id="image_uploader2" name="file" style={{ opacity:0, width: '1px', height: '1px' }} onChange={ e => {
                                                        e.preventDefault();
                                                        new ReadInputFile('image_uploader2', (percent, content)=>{
                                                        if ( content !== '' ) {
                                                            let form = this.state.form;
                                                            form.img = 'filed';
                                                            this.setState({
                                                                uploaded_image2: (content).toString(),
                                                                form: form
                                                            }, ()=>{
                                                                this.gpsFromImage2();
                                                            });

                                                        }
                                                    }, 'url');
                                                    } }
                                                    />
                                                </>:'' }

                                                <div className="form-group"><div className="input-group">
                                                    <div className="input-group-prepend"><span className="d-inline-block input-group-text">típusa:</span></div>
                                                        <select key={"type_id_select_" + this.state.form.typeId} className="form-control" name="typeId" defaultValue={ this.state.form.typeId.toString() } required={true} onChange={ e => this.changeInputHandler(e, 'typeId') }>
                                                            <option value="">Kérem, válasszon!</option>
                                                            { this.state.types.map((item:IType, key:number) => {
                                                                return <option key={key} value={item.id}>
                                                                    { item.name }
                                                                </option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group two_input_50_50">
                                                        <div className="input-group-prepend"><span className="d-inline-block input-group-text">GPS koordináta:</span></div>
                                                        <input className="form-control" type="text" value={this.state.form.latitude.toString()} name="latitude" required={true} placeholder="latitude" onChange={ e => this.changeInputHandler(e, 'latitude') } />
                                                        <input className="form-control" type="text" value={this.state.form.longitude.toString()} name="longitude"
                                                            required={true} placeholder="longitude" onChange={ e => this.changeInputHandler(e, 'longitude') } />
                                                        </div>
                                                </div>
                                                <div className="form-group two_input_33_66">
                                                    <div className="input-group two_input_33_66" id="address_input_group_top">
                                                        <div className="input-group-prepend"><span className="d-inline-block input-group-text">cím:</span></div>
                                                        <input className="form-control" type="text" value={this.state.form.zip} name="zip" required={true} placeholder="i.szám" onChange={ e => this.changeInputHandler(e, 'zip') } />
                                                        <input className="form-control last60" type="text" value={this.state.form.city} name="city" required={true} placeholder="település" onChange={ e => this.changeInputHandler(e, 'city') } />
                                                    </div>
                                                    <div className="input-group" id="address_input_group">
                                                        <div className="input-group-prepend"><span className="d-inline-block input-group-text"></span></div>
                                                        <input className="form-control" type="text" value={this.state.form.address} name="address" required={true} placeholder="utca, házszám"  onChange={ e => this.changeInputHandler(e, 'address') } />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="d-inline-block input-group-text">megjegyzés:</span>
                                                        </div>
                                                        <textarea className="form-control comment_textarea" value={this.state.form.comment} name="comment"  onChange={ e => this.changeInputHandler(e, 'comment') }></textarea>
                                                    </div>
                                                </div>


                                                { this.state.success !== null?<div className="alert alert-success">
                                                        { this.state.success }
                                                </div>:<div className="form-group d-flex justify-content-end button-form-group">
                                                    { this.state.saveing === true?<button type="submit" disabled={true} className="btn btn-primary btn-sm text-center d-flex justify-content-center">MENTÉS FOLYAMATBAN...</button>:<button type="submit" className="btn btn-primary btn-sm text-center d-flex justify-content-center">MENTÉS</button> }


                                                </div>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                key={'map_section_'+this.state.form.latitude.toString() + '_' + this.state.form.longitude.toString() }
                                style={ this.state.form.latitude !== 0 && this.state.form.longitude !== 0?{}:{ display:'none'} }
                                >
                                <Maps
                                    key={'map_'+this.state.form.latitude.toString() + '_' + this.state.form.longitude.toString() }
                                    zoom={12}
                                    api={this.apiKey}
                                    mapId="home_map"
                                    width="100%"
                                    height="400px"
                                    mapTypeControl = {false}
                                    mapStyle="default"
                                    icons = {[
                                        {
                                            name: 'Info',
                                            url: '../assets/img/picker.png'
                                        }
                                    ]}
                                    markers = {[
                                        {
                                            title: this.state.form.zip + ' ' + this.state.form.city + ' ' + this.state.form.address,
                                            lat: this.state.form.latitude,
                                            lng: this.state.form.longitude,
                                            iconName: null
                                        }
                                    ]}
                                />
                            </section>
                        </>
                     }
                    <ReportsFooter setUrl={this.props.setUrl}  categoryId={this.state.typeCategory.id} name={this.state.typeCategory.name}></ReportsFooter>
               </>:'Betöltés...' }
			</>
        );
    }

    private  convertDMSToDD(degrees, minutes, seconds, direction) {
        var dd = degrees + (minutes/60) + (seconds/3600);
        if (direction == "S" || direction == "W") {
            dd = dd * -1;
        }
        return dd;
    }

    private gpsFromImage2() {
        //and orientation image
        setTimeout(()=>{
            var img2:any = document.getElementById("uploaded_image2");
            EXIF.getData(img2, () => {
                var allMetaData = EXIF.getAllTags(img2);
                new ImageResizer(img2.src,1024,768,'jpeg', (content)=>{
                    this.setState({
                        uploaded_image2: content
                    });
                }, 'box', allMetaData);
            });
        },800);
    }
    private gpsFromImage() {
        //and orientation image
        setTimeout(()=>{
            var img:any = document.getElementById("uploaded_image");
            var img2:any = document.getElementById("uploaded_image2");
            EXIF.getData(img, () => {
                var allMetaData = EXIF.getAllTags(img);
                if ( allMetaData.GPSLatitude !== undefined ) {
                    console.log('image has exif');
                    // Calculate latitude decimal
                    var latDegree = allMetaData.GPSLatitude[0].numerator;
                    var latMinute = allMetaData.GPSLatitude[1].numerator;
                    var latSecond = allMetaData.GPSLatitude[2].numerator/allMetaData.GPSLatitude[2].denominator;
                    var latDirection = allMetaData.GPSLatitudeRef;

                    var latFinal = this.convertDMSToDD(latDegree, latMinute, latSecond, latDirection);

                    // Calculate longitude decimal
                    var lonDegree = allMetaData.GPSLongitude[0].numerator;
                    var lonMinute = allMetaData.GPSLongitude[1].numerator;
                    var lonSecond = allMetaData.GPSLongitude[2].numerator/allMetaData.GPSLongitude[2].denominator;
                    var lonDirection = allMetaData.GPSLongitudeRef;
                    console.log(allMetaData);
                    var lonFinal = this.convertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
                    if ( lonFinal !== 0 && latFinal !== 0) {
                        let form       = this.state.form;
                        form.longitude = lonFinal;
                        form.latitude  = latFinal;
                        this.setState({
                                form: form
                        }, () =>{
                            new ImageResizer(img.src,1024,768,'jpeg', (content)=>{
                                this.setState({
                                    uploaded_image: content
                                }, () =>{
                                    this.getNearlyCity();
                                });
                            }, 'box', allMetaData);
                        });
                    }else{
                        new ImageResizer(img.src,1024,768,'jpeg', (content)=>{
                            this.setState({
                                uploaded_image: content
                            }, () =>{
                                if ( this.state.form.longitude !== 0 && this.state.form.latitude !== 0) {
                                    this.getNearlyCity();
                                }
                            });
                        }, 'box', allMetaData);
                    }
                }else{
                    console.log('image exif not found', allMetaData);
                    new ImageResizer(img.src,1024,768,'jpeg', (content)=>{
                        this.setState({
                            uploaded_image: content
                        }, () =>{
                            if ( this.state.form.longitude !== 0 && this.state.form.latitude !== 0) {
                                this.getNearlyCity();
                            }
                        });
                    }, 'box', allMetaData);
                }
            });
        },800);
    }
}
