
import * as React from "react";
import { IgetTypeCategoryResult } from "./NewReportDashboard";
import { Api } from "../lib/Api";
import { IReportItem } from "../interfaces/IReport";
import ReportsHeader from "./ReportsHeader";
import Maps from "../../avernus/googlemaps/source/Maps";
import DateTool from "../../avernus/datetool/source/DateTool";
import ReportsFooter from "./ReportsFooter";
import Config from "../Config";
interface IViewProps {
    setUrl:any
    id:number
    modalMode?:boolean
}

interface IViewState extends IgetReportResult {
    loading     : boolean
}
interface IgetReportResult extends IgetTypeCategoryResult {
    form         : IReportItem,
}

export default class View extends React.Component<IViewProps, IViewState>{
    apiKey       : string;
    modalMode    : boolean;

  	constructor(props:IViewProps){
        super(props);
        this.apiKey          = Config.getAPIKey();
        this.modalMode       = this.props.modalMode!==undefined?this.props.modalMode:false;
        this.state = {
            typeCategory : null,
            form         : null,
            loading      : true,
            error        : null
        }
        this.init();
    }

    init() {
        Api.call('/getReport', { id: this.props.id}, (result:IgetReportResult)=>{
            let error = result.error!==undefined?result.error:null;
            this.setState({
                typeCategory   : result.typeCategory,
                form           : result.form,
                error          : error,
                loading        : false
            });
        });
    }

    private setCompleteClickHandler(evt): void {
        evt.preventDefault();
        Api.call('/reportSetCompleted', { id:this.props.id }, (result:any)=>{
            if ( result.success === true) {
                if ( this.modalMode === true) {
                    this.props.setUrl('/admin/'+this.state.typeCategory.id, 'reinit');
                }else{
                    this.props.setUrl('/reports/'+this.state.typeCategory.id);
                }
            }
        })
    }
    private resendClickHandler(evt): void {
        evt.preventDefault();
        Api.call('/resend', { id:this.props.id }, (result:any)=>{
            if ( result.success === true) {
                if ( this.modalMode === true) {
                    this.props.setUrl('/admin/'+result.typeCategoryId, 'reinit');
                }else{
                    this.props.setUrl('/reports/'+result.typeCategoryId);
                }
            }
        })
    }


   render() {
        let modalMode:boolean = this.modalMode;
        if ( modalMode === false ) {
            document.body.classList.remove('hompe_page');
            document.body.classList.add('footer_without_margin');
        }
        let apiUrl = Api.getServerRouteUrl();
        let img = null;
        if ( this.state.form !== null && this.state.form.img!== '' ) {
            img = apiUrl + '/uploads/'+this.state.form.id+'/' + this.state.form.img;
        }
        let img2 = null;
        if ( this.state.form !== null && this.state.form.img2!== '' ) {
            img2 = apiUrl + '/uploads/'+this.state.form.id+'/' + this.state.form.img2;
        }
        return (
             <>
               { this.state.loading === false?<>
                    { modalMode === false?<ReportsHeader setUrl={this.props.setUrl}  categoryId={this.state.typeCategory.id} name={this.state.typeCategory.name} img={this.state.typeCategory.img}></ReportsHeader>:'' }
                    { this.state.error !== null?<div className="alert alert-danger">
                            { this.state.error }
                    </div>:
                        <>
                        <section className="shadow" id="view_item">
                            <div className="container shadow">
                                <div className="row image_row">
                                    <div className="col text-center">
                                        { img !== null?<img className="img-fluid" src={ img } />:<>
                                            <div>
                                                <br />
                                                <br />
                                                <br />
                                            </div>

                                        </> }
                                        { img2 !== null?<img className="img-fluid" src={ img2 } />:'' }

                                        <button  disabled={this.state.form.status=="new"} className="btn btn-primary btn-sm d-flex justify-content-start align-items-center" id="resend_btn" type="button" onClick={ e => this.resendClickHandler(e) }>
                                            <img src="../assets/img/resend_icon.png" />ismételt jelentés
                                        </button>
                                        <button disabled={this.state.form.status!=="new"}  className="btn btn-success btn-sm d-flex justify-content-start align-items-center"
                                            id="completed_btn" type="button" onClick={ e => this.setCompleteClickHandler(e) }
                                            >
                                                <img src="../assets/img/completed_icon.png" />kész munka
                                        </button>
                                    </div>
                                </div>
                                <div className="row no-gutters form_row">
                                    <div className="col-4">
                                        <p>Helye:</p>
                                    </div>
                                    <div className="col">
                                        <p>{ this.state.form.zip } { this.state.form.city }, { this.state.form.address }<br/><em>GPS { this.state.form.latitude } / { this.state.form.longitude }</em><br/></p>
                                    </div>
                                </div>
                                <div className="row no-gutters form_row">
                                    <div className="col-4">
                                        <p>Típusa:</p>
                                    </div>
                                    <div className="col">
                                        <p>{ this.state.form.typeName }</p>
                                    </div>
                                </div>
                                <div className="row no-gutters form_row">
                                    <div className="col-4">
                                        <p>Bejelentés:</p>
                                    </div>
                                    <div className="col">
                                        <p>{ DateTool.getDateTimeHu(this.state.form.created_at) }, { this.state.form.userName }<br/></p>
                                    </div>
                                </div>
                                <div className="row no-gutters form_row">
                                    <div className="col-4">
                                        <p>Megjegyzés:</p>
                                    </div>
                                    <div className="col">
                                         <p>{ this.state.form.comment }</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                            <section
                                key={'map_section_'+this.state.form.latitude.toString() + '_' + this.state.form.longitude.toString() }
                                style={ this.state.form.latitude !== 0 && this.state.form.longitude !== 0?{}:{ display:'none'} }
                                >
                                <Maps
                                    key={'map_'+this.state.form.latitude.toString() + '_' + this.state.form.longitude.toString() }
                                    zoom={12}
                                    api={this.apiKey}
                                    mapId="home_map"
                                    width="100%"
                                    height="400px"
                                    mapTypeControl = {false}
                                    mapStyle="default"
                                    icons = {[
                                        {
                                            name: 'Info',
                                            url: '../assets/img/picker.png'
                                        }
                                    ]}
                                    markers = {[
                                        {
                                            title: this.state.form.zip + ' ' + this.state.form.city + ' ' + this.state.form.address,
                                            lat: this.state.form.latitude,
                                            lng: this.state.form.longitude,
                                            iconName: null
                                        }
                                    ]}
                                />
                            </section>
                        </>
                     }
                    { modalMode === false?<ReportsFooter setUrl={this.props.setUrl}  categoryId={this.state.typeCategory.id} name={this.state.typeCategory.name}></ReportsFooter>:'' }
               </>:'Betöltés...' }
			</>
        );
    }

}
