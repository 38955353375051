
import * as React from "react";
import { Api } from "../lib/Api";
import { ITypeCategory } from "../interfaces/IType";
import { Url } from "../../avernus/url/source/Url";

interface ITypeCategoriesProps {
    url:string
    setUrl:any
    col2Mode?:boolean
}

interface ITypeCategoriesState {
    items:Array<ITypeCategory>
    loading      : boolean
}


interface IgetTypeCategoriesResult{
    items: ITypeCategory[];
}


export default class TypeCategories extends React.Component<ITypeCategoriesProps, ITypeCategoriesState>{

    private appMode:boolean;

  	constructor(props:ITypeCategoriesProps){
        super(props);
        this.state = {
            items        : [],
            loading      : true
        };
        this.appMode = this.props.url==='/reports/'?true:false;
        this.init();
    }

    init() {
        Api.call('/getTypeCategories', {}, (result:IgetTypeCategoriesResult)=>{
            if ( result.items !== undefined ) {
                this.setState({
                    items   : result.items,
                    loading : false
                });
            }
        });
    }

    render() {
        var col2Mode:boolean = this.props.col2Mode !== undefined?this.props.col2Mode:false;
        var currentItemId:number = parseInt(Url.getUrlPart(1));
        return (
            <div className="container">
                { this.state.loading?<div>
                    Betöltés...
                </div>:
                <div className="row home_menu">
                    { this.state.items.map((item, index)=>{
                        return <div key={'item_'+index} className={col2Mode?"col-6 col-sm-6":"col-4 col-sm-4"}>
                            <a className={'shadow'+(currentItemId===item.id?' active':'')} onClick={ (e) => {
                                this.props.setUrl(this.props.url+item.id);
                            } }
                            >
                                <div className="img" style={{backgroundImage: "url('/assets"+item.img+"')"}}></div><br />
                                <div className="text">
                                    <p>{ item.name }
                                    </p>
                                </div>
                            </a>
                        </div>
                    })}
                    { this.appMode === true?<div key={'item_999'} className={col2Mode?"col-6 col-sm-6":"col-4 col-sm-4"}>
                            <a className={'shadow'} onClick={ (e) => {
                                this.props.setUrl('/settings');
                            } }
                            >
                                <div className="img svg" style={{backgroundImage: "url('/assets/img/settings.svg')", backgroundSize: "70%"}}></div><br />
                                <div className="text">
                                    <p>
                                        Beállítások
                                    </p>
                                </div>
                            </a>
                        </div>:'' }
                </div> }
            </div>
        );
    }
}
