
import * as React from "react";
import { IUser } from "../interfaces/IUser";
import { Api } from "../lib/Api";

interface IEditEmploilerProps {
    id:number
    changed:Function
}

interface IEditEmploilerState {
    success       : string
    error         : string
    form          : IEmploier
    loading       : boolean
    permissionList: Array<IPermissionForCheckbox>
}

interface IPermissionForCheckbox{
    id      :number
    name    :string
    typeId  :number
    types   :Array<IPermissionTypeForcechbox>
}

interface IPermissionTypeForcechbox{
    id            : number
    name          : string
    typeCategoryId: number
}

interface IEmploier extends IUser{
    typesArr:Array<number> //for save form
}

interface ISaveUser{
    success: string
    error  : string
}

interface IOption{
    name: string
    value:string
}

export default class EditEmploiler extends React.Component<IEditEmploilerProps, IEditEmploilerState>{

    private premissionOptions:Array<IOption>;

  	constructor(props:IEditEmploilerProps){
        super(props);
        this.premissionOptions = [
            {
                name: 'Felhasználó',
                value:'user'
            },
            {
                name: 'Admin',
                value:'admin'
            }
        ];
        this.state = {
            success       : null,
            error         : null,
            form          : this.props.id === 0?this.createEmptyForm(): null,
            loading       : this.props.id>0,
            permissionList: []
        };
        this.init();
    }

    createEmptyForm(): IEmploier {
        return {
            id        : 0,
            lastName  : '',
            firstName : '',
            userName  : '',
            phone     : '',
            password  : '',
            schedule  : '',
            permission: 'user',
            typesArr  : []
       };
    }

    private init(){
        if ( this.props.id>0 ) {
            Api.call('/admin/getUser', { id: this.props.id}, (result) =>{
                this.setState({
                     form: result.form,
                     loading: false
                }, () =>{
                    this.getAllPermmission();
                });
            } );
        }else{
            this.getAllPermmission();
        }
    }
    private getAllPermmission(){
        Api.call('/admin/getAllPermmission', {}, (result) =>{
            this.setState({
                    permissionList: result.items
            });
        } );
    }

    private submitHandler(evt): void {
        evt.preventDefault();
        let saveUrl:string = this.props.id>0?'/admin/modifyUser':'/admin/addUser';

        Api.call(saveUrl, this.state.form, (result:ISaveUser)=>{
            this.setState({
                success: result.success,
                error: result.error
            }, () =>{
                this.props.changed(false);
            });
        });
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form = this.state.form;
        form[type] = evt.target.value;
        this.setState({
             form: form
        });
    }

    private removeBtnClickHandler(evt): void {
        evt.preventDefault();
        if (window.confirm("Biztos, hogy törli a \""+this.state.form.userName+"\" felhasználót?")) {
            Api.call('/admin/removeUser', { id: this.props.id }, (result:ISaveUser)=>{
                this.setState({
                    success: result.success,
                    error: result.error
                }, () =>{
                    setTimeout(() => {
                        this.props.changed(true);
                    }, 1000);
                });
            });
        }
    }

    private permissionCheckboxChangeHandler(evt, typeId:number, types:Array<IPermissionTypeForcechbox>=[]): void {
        evt.preventDefault();
        let typesArr        = this.state.form.typesArr;
        let added:boolean   = false;
        let index           = this.state.form.typesArr.indexOf(typeId);
        if ( index > -1 ) {
            typesArr.splice(index, 1);
            added = false;
        }else{
            typesArr.push(typeId);
            added = true;
        }

        if ( types.length > 0 ) {
            for (var key in types) {
                var item  = types[key];
                let index = this.state.form.typesArr.indexOf(item.id);
                if ( added === true) {
                    //forece add types
                    if ( index === -1 ) {
                        typesArr.push(item.id);
                    }
                }else{
                    //force remove types
                     if ( index > -1 ) {
                        typesArr.splice(index, 1);
                    }
                }
            }
        }

        let form        = this.state.form;
        form.typesArr   = typesArr;
        this.setState({
             form: form
        });
    }

    render() {
        return (
            <>
                { (this.state.loading === true || this.state.form === null)?<div>
                    Betöltés...
                </div>:<>
                    <div className="card-header">
                        <h5 className="mb-0">
                            { this.state.form.lastName } { this.state.form.firstName }
                        </h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ e => this.submitHandler(e) }
                        >
                            <div className="form-row" style={{marginBottom:'15px'}}>
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Név:</span>
                                        </div>
                                        <input className="form-control" type="text" value={this.state.form.lastName} onChange={ e => this.changeInputHandler(e,'lastName') }
                                         name="lstName" placeholder="vezetéknév" required={true} />

                                    </div>
                                </div>
                                <div className="col">
                                    <input className="form-control" type="text" name="firstName" value={this.state.form.firstName} onChange={ e => this.changeInputHandler(e,'firstName') } placeholder="keresztnév" required={true}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Beosztás:</span>
                                            </div>
                                            <input className="form-control" type="text" name="schedule" value={this.state.form.schedule} onChange={ e => this.changeInputHandler(e,'schedule') } />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Telefonszám:</span>
                                        </div>
                                        <input className="form-control" type="text" name="phone" value={this.state.form.phone} onChange={ e => this.changeInputHandler(e,'phone') } />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Felhasználónév:</span>
                                            </div>
                                            <input className="form-control" type="text" name="userName" value={this.state.form.userName} onChange={ e => this.changeInputHandler(e,'userName') } required={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Jelszó:</span>
                                        </div>
                                        <input className="form-control" type="text" name="password"  value={this.state.form.password} onChange={ e => this.changeInputHandler(e,'password') }required={this.props.id===0}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Felhasználónév:</span>
                                            </div>
                                            <select className="form-control" defaultValue={ this.state.form.permission } onChange={ e => this.changeInputHandler(e,'permission') }>
                                                { this.premissionOptions.map((item:IOption, key:number) => {
                                                    return <option key={key} value={item.value}>
                                                        { item.name }
                                                    </option>
                                                })}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row" style={{marginTop: '15px'}}>
                                <div className="col">
                                    <p><strong>Jogosultság beállítása:</strong></p>
                                </div>
                            </div>
                            <div className="form-row">
                                { this.state.permissionList.map((item, index) =>{
                                    return <div key={'checkbox_category_'+index} className="col-lg-4"  style={{marginBottom: '10px'}}>
                                            <div className="form-check" style={{marginBottom: '5px'}}>
                                                <input
                                                    key={"formCheck-"+ item.typeId+'_'+(this.state.form.typesArr.indexOf(item.typeId)>-1?'1':'0')}
                                                    checked={this.state.form.typesArr.indexOf(item.typeId)>-1?true:false}
                                                    onChange={ (e) => {this.permissionCheckboxChangeHandler(e, item.typeId, item.types) }} className="form-check-input" type="checkbox"
                                                    id={"formCheck-"+ item.typeId} /><label className="form-check-label"
                                                    htmlFor={"formCheck-"+ item.typeId}><strong>{ item.name }</strong></label>
                                            </div>
                                            { item.types.map((type, tindex)=>{
                                                return <div className="form-check" key={'checkbox_category_'+index+'_'+tindex}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            key={"formCheck-"+ type.id+'_'+(this.state.form.typesArr.indexOf(type.id)>-1?'1':'0')}
                                                            checked={this.state.form.typesArr.indexOf(type.id)>-1?true:false}
                                                            onChange={ (e) => this.permissionCheckboxChangeHandler(e, type.id) }
                                                            id={"formCheck-"+ type.id}/><label className="form-check-label" htmlFor={"formCheck-"+ type.id}>{ type.name }</label>
                                                    </div>
                                            }) }
                                        </div>
                                    })
                                }
                            </div>
                            <div className="form-row" style={{
                                marginTop: '15px'
                            }}>
                                <div className="col-sm-12">
                                    { this.state.error !== null?<div className="alert alert-danger">
                                            { this.state.error }
                                    </div>:''}
                                    { this.state.success !== null?<div className="alert alert-success">
                                            { this.state.success }
                                    </div>:''
                                    }
                                    <div className="form-group d-flex justify-content-end button-form-group">
                                        { this.props.id >0 ?<button style={{ marginRight: '10px' }} className="btn btn-danger btn-sm" id="emploiler_remove_btn" type="button" onClick={ e => this.removeBtnClickHandler(e) }
                                        >Törlés</button>:'' }

                                        <button className="btn btn-secondary btn-sm" id="emploiler_save_btn" type="submit">MENTÉS</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </> }
			</>
        );
    }
}
