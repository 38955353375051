export class Url {
    private static urls:any = null;

    public static get(field:string):string{
        if ( this.urls === null ) {
            this.urls = {};
            var url = decodeURIComponent(window.location.href);
            var firstParamIndex:number = url.indexOf('?');
            if ( firstParamIndex > -1 ) {
                var paramsString = url.substr(firstParamIndex+1)
                var temp:Array<string> = null;
                if ( paramsString.indexOf('&') > -1 ) {
                    temp = paramsString.split('&');
                }else{
                    temp = [paramsString];
                }
                for (var key in temp) {
                    var item = temp[key].split('=');
                    this.urls[item[0]] = item[1];
                }
            }
        }
        if ( this.urls[field] !== undefined ) {
            return this.urls[field];
        }else{
            return null;
        }
    }

    public static setUrl(url:string, data:any=null, title:string=null):void{
        history.pushState(data, title, url);
    }
    public static getUrl():string{
        return window.location.pathname.substr(1);
    }

    public static isUrl(pattern:RegExp):boolean {
       return pattern.test(this.getUrl());
    }

    public static getUrlPart(part:number):string {
        var temp:Array<string> = this.getUrl().split('/');
        if (  temp[part] !== undefined ) {
            return temp[part];
        }else{
            console.error('getUrlPart error, not found url part');
        }


       return null;
    }
}
