
import * as React from "react";

interface IReportsHeaderProps {
    setUrl      : any
    name        : string
    img         ?: string
    categoryId  ?: number
}

interface IReportsHeaderState {
}

export default class ReportsHeader extends React.Component<IReportsHeaderProps, IReportsHeaderState>{

  	constructor(props:IReportsHeaderProps){
        super(props);
    }

    render() {
        return (
            <section className="shadow" id="header">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex justify-content-start align-items-center logo_col">
                            <img id="logo" src="../assets/img/logo.png" onClick={ e => this.props.setUrl('/index') } />
                        </div>

                        <div className="col d-flex title_col" onClick={ e => {
                                if ( this.props.categoryId !== undefined ) {
                                    this.props.setUrl('/reports/'+this.props.categoryId);
                                }
                            }}>
                            <figure className="figure d-flex">
                                <figcaption className="figure-caption d-flex align-items-center">{ this.props.name }
                                </figcaption>
                                { this.props.img !== undefined?<img className="img-fluid figure-img d-flex justify-content-end align-items-center" src={"../assets" + this.props.img} style={{ filter: "invert(100%)" }}/>:'' }
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
