
import * as React from "react";
import AdminHeader from "./AdminHeader";
import TypeCategories from "../components/TypeCategories";
import Config from "../Config";
import { Api } from "../lib/Api";
import { IgetReportsResult } from "../components/ReportsMap";
import Maps from "../../avernus/googlemaps/source/Maps";
import DataTable from 'react-data-table-component';
import DateTool from "../../avernus/datetool/source/DateTool";
import View from "../components/View";
import * as $ from "jquery";
import { Url } from "../../avernus/url/source/Url";

interface IAdminProps {
    setUrl      :any
    categoryId  :number
}

interface IAdminState extends IgetReportsResult {
    loading      : boolean
    mapHeight    : number
    currentItemId: number
    exportLoading: boolean
    downloadLink : string
    tableFilter  :string
}

interface IField{
    label:string
    fieldName:string
}

interface IExport{
    fields:Array<IField>
    items:Array<any>
}

export default class Admin extends React.Component<IAdminProps, IAdminState>{
    apiKey: string;
    private tableColumns:any;
    private exportData:IExport

  	constructor(props:IAdminProps){
        super(props);
        this.apiKey          = Config.getAPIKey();
        this.exportData      = {
            fields: [],
            items: []
        };
        this.state = {
            typeCategory    : null,
            items           : [],
            loading         : true,
            error           : null,
            mapHeight       : 400,
            currentItemId   : null,
            exportLoading   : false,
            downloadLink    : null,
            tableFilter     : ''
        }
        this.tableColumns = [
            {
                name: 'Típus',
                selector: 'typeName',
                sortable: true,
                width:          "150px"
            },
            {
                name: 'Bejelentés dátuma',
                selector: 'created_at',
                sortable: true,
            },
            {
                name: 'Cím',
                selector: 'address',
                sortable: true,
            },
            {
                name: 'Munkatárs',
                selector: 'userName',
                sortable: true,
            },
            {
                name: 'Megjegyzés',
                selector: 'comment',
                sortable: true,
                width:          "350px"
            },
            {
                name: 'Elkészült',
                selector: 'completedAt',
                sortable: true,
                cell: (data) => <>
                    { data.completedAt !== ''?<><i className="icon ion-ios-checkmark"></i>&nbsp;{data.completedAt}</>:'' }
                </>
            },
            {
                cell: (data) => <button className="btn btn-secondary btn-sm" onClick={(e) => {
                    e.preventDefault();
                    this.openViewInModal(data.id);
                }}>Adatlap</button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                cell: (data) => <button className="btn btn-danger btn-block btn-sm" onClick={(e) => {
                    e.preventDefault();
                    let confirm = window.confirm('Biztos, hogy töröli?');
                    if ( confirm === true) {
                        Api.call('/removeReport', { id: data.id }, ()=>{
                            this.init();
                        });
                    }
                }}>TÖRLÉS</button>,
                ignoreRowClick: true,
                allowOverflow:  true,
                button:         true
            }
        ];
        for (var key in this.tableColumns) {
            var item = this.tableColumns[key];
            if ( item.name !== undefined ) {
                this.exportData.fields.push({
                    label:      item.name,
                    fieldName:  item.selector
                });
            }
        }

        this.init();
    }

    init() {
        Api.call('/getReports', { typeCategoryId: this.props.categoryId}, (result:IgetReportsResult)=>{
            if ( result.items !== undefined ) {
                this.setState({
                    items          : result.items,
                    typeCategory   : result.typeCategory,
                    error          : result.error!==undefined?result.error:null,
                    loading        : false
                });
            }
        });
    }

    getMapHeight() {
        setTimeout(() => {
            if ( this.state.mapHeight === 400 && document.querySelector('.fluid_center') !== null ) {
                this.setState({
                    mapHeight: (document.querySelector('.fluid_center') as HTMLDivElement).offsetHeight + 20
                });
            }
        }, 200);
    }

    private openViewInModal(id:number):void{
        this.setState({
             currentItemId: id
        });
    }

    private exportBtnClickHandler(evt): void {
        evt.preventDefault();
        this.setState({
            exportLoading: true,
            downloadLink: null
        }, () =>{
            Api.call('/admin/export', { fields: this.exportData.fields, items:this.exportData.items}, (result:any)=>{
                if ( result.url !== null ) {
                    let apiUrl = Api.getServerRouteUrl();
                    this.setState({
                        exportLoading:false,
                        downloadLink: apiUrl+result.url
                    });
                }
            });
        });
    }

    private tableFilterChangeHandler(evt): void {
        evt.preventDefault();
        this.setState({
             tableFilter: evt.target.value
        });

    }


    render() {
        document.body.classList.remove('hompe_page');
        document.body.classList.remove('modal-open');
        document.body.classList.add('no_bg');
        document.body.id='admin';
        let error = this.state.error;
        if ( this.state.items.length===0 ) {
            error = 'Nincs találat!';
        }
        let markers = []
        let uniqueMarkersNum = 0;
        let markerCheck= [];
        let items  = []
        this.exportData.items = [];
        let tableFilter = this.state.tableFilter.toLowerCase();
        for (var key in this.state.items) {
            var item = this.state.items[key];
            var newItem:any = item;
            if ( newItem.address.indexOf(item.zip) !== 0 ) {
                newItem.address = item.zip + ' ' + item.city + ' ' + item.address;
            }
            newItem.created_at= DateTool.getDateHu(item.created_at);
            newItem.completedAt = item.status==='completed'?DateTool.getDateHu(item.updated_at):'';


            var exportItem:Array<string|number> = [];
            var skip:boolean = tableFilter !== ''?true:false;
            for (var ekey in this.exportData.fields) {
                var eitem = this.exportData.fields[ekey];
                exportItem.push(newItem[eitem.fieldName]);
                if ( skip === true && tableFilter !== '' && newItem[eitem.fieldName].toString().toLowerCase().indexOf(tableFilter) > -1 ) {
                    skip = false;
                }
            }
            if ( skip === false ) {
                this.exportData.items.push(exportItem);
                markers.push({
                    id      : item.id,
                    title   : newItem.address,
                    typeName: newItem.typeName,
                    lat     : item.latitude,
                    lng     : item.longitude,
                    iconName: null
                });
                var find = item.latitude+'_'+item.longitude;
                if ( markerCheck.indexOf(find) == -1 ) {
                    markerCheck.push(find);
                    ++uniqueMarkersNum;
                }
                items.push(newItem);
            }
        }
        this.getMapHeight();
        setTimeout(() => {
            if ( document.querySelector('#viewModal') !== null ) {
                $('#viewModal').modal({show:true});
            }
        }, 200);
        return (
            <>
                <AdminHeader setUrl={this.props.setUrl}></AdminHeader>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 col-lg-2 col-xl-2 col-md-3">
                                <div className="row home_menu" id="admin_left_menu">
                                    <TypeCategories col2Mode={true} url="/admin/" setUrl={this.props.setUrl}></TypeCategories>
                                </div>
                            </div>
                            { this.state.loading === false?<>
                            <div className="col-md-4 col-lg-10 col-xl-10">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="mb-0">{ this.state.typeCategory.name }
                                                </h5>
                                                <div className="d-xl-flex align-items-xl-center header_right">
                                                    <input type="search" className="form-control-sm" value={this.state.tableFilter} placeholder="Keresés a táblázatban" style={{marginRight: '10px', width: '180px'}} onChange={ e => this.tableFilterChangeHandler(e) }
                                                     />
                                                    { items.length > 0?<>
                                                        { this.state.exportLoading===true?<div>
                                                            Exportálás folyamatban...
                                                        </div>:<>
                                                            { this.state.downloadLink !== null?<a href={this.state.downloadLink} className="btn btn-dark btn-sm d-xl-flex align-items-xl-center export_btn" target="_BLANK">
                                                                <img src="/assets/img/export.png" /> LETÖLTÉS
                                                            </a>:<button className="btn btn-dark btn-sm d-xl-flex align-items-xl-center export_btn" onClick={ e => this.exportBtnClickHandler(e) }
                                                            type="button"><img src="/assets/img/export.png" />EXPORTÁLÁS</button> }


                                                        </> }
                                                    </>:'' }
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                { this.state.currentItemId !== null?<>
                                                    <div role="dialog" className="modal fade" id="viewModal">
                                                        <div className="modal-dialog modal-md" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-body">
                                                                    <div id="view_modal_mode">
                                                                            <View key={'view_'+this.state.currentItemId} modalMode={true} setUrl={(url, method) =>{
                                                                                if ( method !== undefined && method === 'reinit' ) {
                                                                                    if ( document.querySelector('#viewModal') !== null ) {
                                                                                        $('.modal-backdrop').remove();
                                                                                    }
                                                                                    this.setState({
                                                                                         currentItemId: null
                                                                                    }, () =>{
                                                                                        this.init();
                                                                                    });
                                                                                }
                                                                                this.props.setUrl(url);
                                                                            }} id={this.state.currentItemId} />
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>:'' }
                                                { this.state.items === null?<div>
                                                    Betöltés...
                                                </div>: (this.state.items.length===0?<div>
                                                        Nincs találat!
                                                </div>:<DataTable
                                                    noHeader={true}
                                                    className="table emploiler_table"
                                                    columns={this.tableColumns}
                                                    data={items}
                                                    striped={true}
                                                />)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { markers.length > 0?<div className="row">
                                    <div className="col">
                                        <Maps
                                            key={'map_list' }
                                            zoom={ uniqueMarkersNum>1?null:12 }
                                            api={this.apiKey}
                                            mapId="admin_map"
                                            width="100%"
                                            height={ this.state.mapHeight+ "px"}
                                            mapTypeControl = {false}
                                            mapStyle="default"
                                            icons = {[
                                                {
                                                    name: 'Info',
                                                    url: '/assets/img/picker.png'
                                                }
                                            ]}
                                            markers = { markers }
                                            markerClickHandler={ (marker:any, infowindow:any, markerIcon:any) => {
                                                let popover = infowindow.setContent(
                                                    '<strong>' + marker.typeName + '</strong>'
                                                    + '<a id="map_btn_'+marker.id+'" href="#" class="map_btn_eye"><i class="icon ion-eye"></i></a>'
                                                    + '<br /><br />' + marker.title
                                                );
                                                infowindow.open((window as any).map, markerIcon);
                                                setTimeout(() => {
                                                    document.getElementById('map_btn_'+marker.id).addEventListener('click', (e) => {
                                                        e.preventDefault();
                                                        this.openViewInModal(marker.id);
                                                    });
                                                }, 400);
                                            } }
                                        />
                                    </div>
                                </div>:'' }
                            </div>
                            </>:'Betöltés...' }

                        </div>
                    </div>
                </div>
			</>
        );
    }
}
