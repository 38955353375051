
import * as React from "react";
import AdminHeader from "./AdminHeader";
import TypeCategories from "../components/TypeCategories";
import DataTable from 'react-data-table-component';
import { IUser } from "../interfaces/IUser";
import { Api } from "../lib/Api";
import EditEmploiler from "./EditEmploiler";

interface IAdminEmploilerProps {
    setUrl:any
}

interface IAdminEmploilerState {
    userItems:Array<IUserForList>
    openedUserId:number
}
interface IUserForList{
    id: number
    name:string
    schedule:string
    phone:string
    permission:string
}

export default class AdminEmploiler extends React.Component<IAdminEmploilerProps, IAdminEmploilerState>{

    private userTableColumns:any;

  	constructor(props:IAdminEmploilerProps){
        super(props);
        this.userTableColumns = [
            {
                name: 'Név',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Beosztás',
                selector: 'schedule',
                sortable: true
            },
            {
                name: 'Telefonszám',
                selector: 'phone',
                sortable: true
            },
            {
                name: 'Jogosultság',
                selector: 'permission',
                sortable: true
            },
            {
                cell: (data) => <button className="btn btn-secondary btn-sm" onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                         openedUserId: data.id
                    });

                }}>Adatlap</button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }
        ];
        this.state = {
            userItems: null,
            openedUserId: null
        };
        this.init();
    }

    private init(){
        Api.call('/admin/getListForTable', {}, (result)=>{
            this.setState({
                 userItems: result.items
            });
        });
    }

    render() {
        document.body.classList.remove('hompe_page');
        document.body.classList.add('no_bg');
        document.body.id='admin';
        return (
            <>
                <AdminHeader setUrl={this.props.setUrl}></AdminHeader>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 col-lg-2 col-xl-2 col-md-3">
                                <div className="row home_menu" id="admin_left_menu">
                                    <TypeCategories col2Mode={true} url="/admin/" setUrl={this.props.setUrl}></TypeCategories>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-10 col-xl-10">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="mb-0">Munkatársak</h5>
                                            </div>
                                            <div className="card-body">
                                                { this.state.userItems === null?<div>
                                                    Betöltés...
                                                </div>: (this.state.userItems.length===0?<div>
                                                        Nincs találat!
                                                </div>:<DataTable
                                                    noHeader={true}
                                                    className="table emploiler_table"
                                                    columns={this.userTableColumns}
                                                    data={this.state.userItems}
                                                    striped={true}
                                                />)}
                                                <div className="form-group d-flex justify-content-end button-form-group">
                                                    <button className="btn btn-sm btn-primary" id="create_new_emploiler_btn" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            openedUserId: 0
                                                        });

                                                    }}>
                                                        Új felhasználó felvitele
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="card">
                                            { this.state.openedUserId !== null?<EditEmploiler id={this.state.openedUserId} key={'user_form_'+this.state.openedUserId}  changed={ (close) => {
                                                if ( close === true ) {
                                                    this.setState({
                                                         openedUserId: null
                                                    }, () =>{
                                                        this.init();
                                                    });
                                                }else{
                                                    this.init();
                                                }

                                            } }
                                            ></EditEmploiler>:'' }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</>
        );
    }
}
