/*
 ha sikerees a cb percent 100 és a content-ben van tartalom
*/
export interface IREadInputFileCallback{
    (percent:number, content:string|ArrayBuffer):void
}

export class ReadInputFile {
    private reader: FileReader;
    private input: HTMLInputElement;
    private cb:IREadInputFileCallback;
    private mode:string;
    constructor(inputId: string, cb:IREadInputFileCallback, mode:string='text') {
        this.mode = mode;
        this.cb     = cb;
        this.input  = document.getElementById(inputId) as HTMLInputElement;
        this.start();
    }

    public abortRead() {
        this.reader.abort();
    }

    protected errorHandler(evt) {
        switch (evt.target.error.code) {
            case evt.target.error.NOT_FOUND_ERR:
                alert('File Not Found!');
                break;
            case evt.target.error.NOT_READABLE_ERR:
                alert('File is not readable');
                break;
            case evt.target.error.ABORT_ERR:
                break; // noop
            default:
                alert('An error occurred reading this file.');
        };
    }

    protected updateProgress(evt) {
        if (evt.lengthComputable) {
            var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
            if (percentLoaded < 100) {
                 this.refreshPrencet(percentLoaded, '');
            }
        }
    }

    private refreshPrencet(percent:number, content:string|ArrayBuffer){
        this.cb(percent, content);
    }

    protected start() {
        this.reader = new FileReader();
        this.reader.onerror = (evt)=>{
            this.errorHandler(evt);
        };
        this.reader.onprogress = (evt)=>{
            this.updateProgress(evt);
        };
        this.reader.onabort = (_e) => {
            alert('File read cancelled');
        };
        this.reader.onloadstart = (_e) => {
            this.refreshPrencet(0, '');
        };
        this.reader.onload = (_e) => {
            if ( this.mode === 'text' ) {
                this.refreshPrencet(100, this.reader.result.toString());
            }else if ( this.mode === 'url' ) {
                this.refreshPrencet(100, this.reader.result);
            }else{
                this.refreshPrencet(100, this.reader.result);
            }
        }
        if ( this.mode === 'text' ) {
            this.reader.readAsText(this.input.files[0]);
        }else if ( this.mode === 'url' ) {
            this.reader.readAsDataURL(this.input.files[0]);
        }else{
            this.reader.readAsBinaryString(this.input.files[0]);
        }
    }
}
