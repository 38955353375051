
import * as React from "react";
declare var google:any;
export interface IMapsWindow extends Window{
    reactMaps: any;
    map: any
}
export interface IIcon{
    name: string,
    url: string
}
export interface IMarker{
    title: string,
    lat: number,
    lng: number,
    iconName: string
}
interface ImarkerClickHandler{
    (ret:IMarker, infowindow:any, markerIcon:any):void
}

interface IMapsProps {
    api:string
    mapId:string
    width:string
    height:string
    icons:Array<IIcon>
    markers:Array<IMarker>
    mapTypeControl:boolean
    mapStyle: string /*default, silver, night, retro, hiding*/
    zoom:number
    externalDivMode?:boolean
    markerClickHandler?:ImarkerClickHandler
}

interface IMapsState {
    loaded:boolean
}

export default class Maps extends React.Component<IMapsProps, IMapsState>{

    private styles:any;
    private lastMarkers:string

  	constructor(props:IMapsProps){
        super(props);
        this.state = {
            loaded: false
        }
        this.setStyles();
        this.loadJs();
    }

    private setStyles():void{
        this.styles = {
        default: null,
        silver: [
            {
            elementType: 'geometry',
            stylers: [{color: '#f5f5f5'}]
            },
            {
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
            },
            {
            elementType: 'labels.text.fill',
            stylers: [{color: '#616161'}]
            },
            {
            elementType: 'labels.text.stroke',
            stylers: [{color: '#f5f5f5'}]
            },
            {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{color: '#bdbdbd'}]
            },
            {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{color: '#eeeeee'}]
            },
            {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#757575'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{color: '#e5e5e5'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9e9e9e'}]
            },
            {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#ffffff'}]
            },
            {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [{color: '#757575'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#dadada'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{color: '#616161'}]
            },
            {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9e9e9e'}]
            },
            {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{color: '#e5e5e5'}]
            },
            {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{color: '#eeeeee'}]
            },
            {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#c9c9c9'}]
            },
            {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9e9e9e'}]
            }
        ],

        night: [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
            },
            {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{color: '#263c3f'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#6b9a76'}]
            },
            {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#38414e'}]
            },
            {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{color: '#212a37'}]
            },
            {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9ca5b3'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#746855'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{color: '#1f2835'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f3d19c'}]
            },
            {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{color: '#2f3948'}]
            },
            {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d59563'}]
            },
            {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#17263c'}]
            },
            {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#515c6d'}]
            },
            {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{color: '#17263c'}]
            }
        ],

        retro: [
            {elementType: 'geometry', stylers: [{color: '#ebe3cd'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#523735'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#f5f1e6'}]},
            {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [{color: '#c9b2a6'}]
            },
            {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [{color: '#dcd2be'}]
            },
            {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [{color: '#ae9e90'}]
            },
            {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [{color: '#dfd2ae'}]
            },
            {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [{color: '#dfd2ae'}]
            },
            {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#93817c'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{color: '#a5b076'}]
            },
            {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#447530'}]
            },
            {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#f5f1e6'}]
            },
            {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [{color: '#fdfcf8'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#f8c967'}]
            },
            {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{color: '#e9bc62'}]
            },
            {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [{color: '#e98d58'}]
            },
            {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [{color: '#db8555'}]
            },
            {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [{color: '#806b63'}]
            },
            {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [{color: '#dfd2ae'}]
            },
            {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [{color: '#8f7d77'}]
            },
            {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [{color: '#ebe3cd'}]
            },
            {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [{color: '#dfd2ae'}]
            },
            {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [{color: '#b9d3c2'}]
            },
            {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#92998d'}]
            }
        ],

        hiding: [
            {
            featureType: 'poi.business',
            stylers: [{visibility: 'off'}]
            },
            {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{visibility: 'off'}]
            }
        ]
        };
    }


    componentWillReceiveProps(){
        if( this.props.markers!== undefined && this.lastMarkers !== JSON.stringify(this.props.markers) ) {
            this.init();
        }
    }

    private getIcon(iconName:string):string{
        for (var key in this.props.icons) {
            var item = this.props.icons[key];
            if ( item.name === iconName ) {
                return item.url;
            }
        }

        return '';
    }

    private init():void{
        this.lastMarkers = JSON.stringify(this.props.markers);
        this.setState({
            loaded: true
        }, () =>{
            if ( document.getElementById(this.props.mapId) !== null ) {
                this.setMap();
            }else{
                alert('Map div not found!');
            }
        });
    }

    private setMap():void{
        (window as IMapsWindow).map= new google.maps.Map(document.getElementById(this.props.mapId), {
            mapTypeControl: this.props.mapTypeControl,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: this.styles[this.props.mapStyle]
        });
        var infowindow = new google.maps.InfoWindow();
        var bounds = new google.maps.LatLngBounds();
        var marker = null;
        for (var key in this.props.markers) {
            var item = this.props.markers[key];
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(item.lat, item.lng),
                icon: this.getIcon(item.iconName),
                map: (window as IMapsWindow).map
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', ( (marker, i) => {
                return () => {
                    if ( this.props.markerClickHandler !== undefined ) {
                        this.props.markerClickHandler(this.props.markers[i], infowindow, marker);
                    }else{
                        infowindow.setContent(this.props.markers[i].title);
                        infowindow.open((window as IMapsWindow).map, marker);
                    }
                }
            })(marker, key));
        }

        (window as IMapsWindow).map.fitBounds(bounds);

        var listener = google.maps.event.addListener((window as IMapsWindow).map, "idle", () => {
            if ( this.props.zoom !== null ) {
                if ( this.props.markers.length > 1 ) {
                    var latlng = [];
                    for (var key in this.props.markers) {
                        var item = this.props.markers[key];
                        latlng.push(new google.maps.LatLng(item.lat, item.lng));
                    }

                    var latlngbounds = new google.maps.LatLngBounds();
                    for (var i = 0; i < latlng.length; i++) {
                        latlngbounds.extend(latlng[i]);
                    }
                    (window as IMapsWindow).map.fitBounds(latlngbounds);
                }else{
                    (window as IMapsWindow).map.setZoom(this.props.zoom);
                }
            }else{
                var latlng = [];
                for (var key in this.props.markers) {
                    var item = this.props.markers[key];
                    latlng.push(new google.maps.LatLng(item.lat, item.lng));
                }

                var latlngbounds = new google.maps.LatLngBounds();
                for (var i = 0; i < latlng.length; i++) {
                    latlngbounds.extend(latlng[i]);
                }
                (window as IMapsWindow).map.fitBounds(latlngbounds);
            }
            google.maps.event.removeListener(listener);
        });
    }

    private loadJs():void{
        if ( (window as IMapsWindow).reactMaps === undefined ) {
            (window as IMapsWindow).reactMaps = {};
        }
        (window as IMapsWindow).reactMaps[this.props.mapId] = () => {
            this.init();
        };
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=' + this.props.api + '&callback=reactMaps.'+this.props.mapId;
        document.body.appendChild(script);
    }

    render() {
        var id = this.props.mapId;
        if ( this.props.externalDivMode !== undefined && this.props.externalDivMode === true ) {
            id += '_external_not_this';
        }
        return (
            <div>
                { this.state.loaded === true?<div id={id} style={{
                   width: this.props.width,
                   height: this.props.height
                }}></div>:<div>
                    Betöltés...
                </div>
                }
			</div>
        );
    }
}
