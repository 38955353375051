export default class DateTool {
	constructor(){
	}

    private static getMonth(date:Date):string{
        var temp:string = (date.getMonth()+1).toString();
        if ( temp.length < 2 ) {
            temp = '0' + temp;
        }
        return temp;
    }

    private static getDay(date:Date):string{
        var temp:string = date.getDate().toString();
        if ( temp.length < 2 ) {
            temp = '0' + temp;
        }
        return temp;
    }

	public static getDate(currentDate?:string){
        if ( currentDate != undefined && currentDate.indexOf('.') > -1 ) {
            var temp = currentDate.split('.');
            currentDate = temp[0]+'-'+temp[1]+'-'+temp[2];
        }

        var date:Date = null;
        if ( currentDate === undefined ) {
            date = new Date();
        }else{
            if ( currentDate === '' ) return '';
            date = new Date(currentDate.substr(0,8).replace(/\./g, '-'));
        }
        return date.getFullYear() + '-' + this.getMonth(date) + '-' + this.getDay(date);
    }

	public static getDateHu(currentDate?:string){
        var date:Date = null;
        if ( currentDate === undefined ) {
            date = new Date();
        }else{
            if ( currentDate === '' ) return '';
            date = new Date(currentDate.substr(0,10).replace(/\./g, '-'));
        }
        return date.getFullYear() + '.' + this.getMonth(date) + '.' + this.getDay(date) + '.';
    }

    public static getDateTime(currentDate?:string){
        var date:Date = null;
        if ( currentDate === undefined ) {
            date = new Date();
        }else{
            if ( currentDate === '' ) return '';
            date = new Date(currentDate.substr(0,10).replace(/\./g, '-'));
        }
        return date.getFullYear() + '-' + this.getMonth(date) + '-' + this.getDay(date) + ' ' + date.getHours() +':'+ date.getMinutes();
    }
    public static getDateTimeHu(currentDate?:string){
        var date:Date = null;
        if ( currentDate === undefined ) {
            date = new Date();
        }else{
            if ( currentDate === '' ) return '';
            date = new Date(currentDate.substr(0,10).replace(/\./g, '-'));
        }
        return date.getFullYear() + '.' + this.getMonth(date) + '.' + this.getDay(date) + '. ' + date.getHours() +':'+ date.getMinutes();
    }
}
new DateTool();
