
import * as React from "react";
import { TStorage } from "../../avernus/tstorage/TStorage";
import { Api } from "../lib/Api";

interface IFooterProps {
    setUrl:any
}

interface IFooterState {
}

export default class Footer extends React.Component<IFooterProps, IFooterState>{

  	constructor(props:IFooterProps){
        super(props);
    }

    private logoutClickHandler(evt): void {
        evt.preventDefault();
        Api.cleanStorage();
        let a:HTMLAnchorElement = evt.target;
        window.location.href= a.href;
    }

    render() {
        var name = new TStorage().get('name', '');
        var isAdmin = Api.getPermission().indexOf('admin')>-1?true:false;
        return (
            <div className="container">
                <div className="row footer">
                    <div className="col-8 col-sm-10 d-flex flex-grow-0 justify-content-start align-items-center align-self-center login_box">
                        <p>Bejelentkezve: <strong>{ name }</strong></p>
                    </div>
                    <div className="col-2 col-sm-1 d-flex flex-grow-0 justify-content-end align-items-center">
                        { isAdmin === true?<a className="text-light" href="" onClick={ e => {
                            e.preventDefault();
                            this.props.setUrl('/admin');
                        } }>Admin</a>:'' }
                    </div>

                    <div className="col-2 col-sm-1 d-flex flex-grow-0 justify-content-end align-items-center">
                        <a className="text-light" href="/" onClick={ e => this.logoutClickHandler(e) }
                        >Kilépés</a>
                    </div>
                </div>
            </div>
        );
    }
}
