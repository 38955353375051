
import * as React from "react";
import { Url } from "../../avernus/url/source/Url";
import 'jquery';
import 'bootstrap';
import { TStorage } from "../../avernus/tstorage/TStorage";
import { Api } from "../lib/Api";

interface IAdminHeaderProps {
    setUrl:any
}

interface IAdminHeaderState {
}

export default class AdminHeader extends React.Component<IAdminHeaderProps, IAdminHeaderState>{

  	constructor(props:IAdminHeaderProps){
        super(props);
    }

    private logoutClickHandler(evt): void {
        evt.preventDefault();
        Api.cleanStorage();
        let a:HTMLAnchorElement = evt.target;
        window.location.href= a.href;
    }


    render() {
        var name = new TStorage().get('name', '');
        let permission = Api.getPermission();
        return (
            <div id="admin_header">
                <nav className="navbar navbar-light navbar-expand-md navigation-clean">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="" onClick={ e => {
                            e.preventDefault();
                            this.props.setUrl('/admin');
                        } }
                        >
                            <img src="/assets/img/logo.png" />
                        </a>
                        <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1"><span className="sr-only">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
                        <div
                            className="collapse navbar-collapse" id="navcol-1">
                            <ul className="nav navbar-nav ml-auto">
                                <a className="nav-link" href="" onClick={ e => {
                                         e.preventDefault();
                                        this.props.setUrl('/index');
                                    } }>APP
                                </a>
                                { permission === 'admin'?<li className="nav-item" role="presentation">
                                    <a className={Url.isUrl(/admin\/emploiler/)?"nav-link active":"nav-link"} href="" onClick={ e => {
                                         e.preventDefault();
                                        this.props.setUrl('/admin/emploiler');
                                    } }>MUNKATÁRSAK
                                    </a>
                                </li>:'' }

                                <li className="dropdown nav-item"><a className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="javascript:void(0)">{ name }
                                 </a>
                                    <div className="dropdown-menu" role="menu">
                                        <a className="dropdown-item" role="presentation" href="/admin" onClick={ e => this.logoutClickHandler(e) }
                                        >Kijelentkezés</a>
                                    </div>
                                </li>
                            </ul>
                    </div>
                    </div>
                </nav>
            </div>
        );
    }
}
