
import * as React from "react";
import Footer from "./Footer";
import ReportsHeader from "./ReportsHeader";
import { ITypeCategory } from "../interfaces/IType";
import { IReportItem } from "../interfaces/IReport";
import { Api } from "../lib/Api";
import Maps, { IMapsWindow } from "../../avernus/googlemaps/source/Maps";
import Config from "../Config";
interface IReportsMapProps {
    setUrl    : any
    categoryId: number
}

interface IReportsMapState extends IgetReportsResult {
    loading     : boolean
    mapHeight   : number
}
export interface IgetReportsResult{
    items       : Array<IReportItem>
    typeCategory: ITypeCategory
    error:string
}

declare var google:any;

export default class ReportsMap extends React.Component<IReportsMapProps, IReportsMapState>{
    apiKey: string;

  	constructor(props:IReportsMapProps){
        super(props);
        this.apiKey          = Config.getAPIKey();;
        this.state = {
            typeCategory : null,
            items        : [],
            loading      : true,
            error        : null,
            mapHeight    : 400
        }
        this.init();
    }

    init() {
        Api.call('/getReports', { typeCategoryId: this.props.categoryId}, (result:IgetReportsResult)=>{
            if ( result.items !== undefined ) {
                this.setState({
                    items          : result.items,
                    typeCategory   : result.typeCategory,
                    error          : result.error!==undefined?result.error:null,
                    loading        : false
                });
            }
        });
    }

    render() {
        document.body.classList.remove('hompe_page');
        document.body.classList.add('footer_without_margin');
        let error = this.state.error;
        if ( this.state.items.length===0 ) {
            error = 'Nincs találat!';
        }
        let markers = []
        for (var key in this.state.items) {
            var item = this.state.items[key];
            markers.push({
                id      : item.id,
                title   : item.zip + ' ' + item.city + ' ' + item.address,
                typeName: item.typeName,
                lat     : item.latitude,
                lng     : item.longitude,
                iconName: null
            });
        }
        this.getMapHeight();
        return (
            <>
               { this.state.loading === false?<>
                    <ReportsHeader setUrl={this.props.setUrl} name={this.state.typeCategory.name} categoryId={this.props.categoryId} img={this.state.typeCategory.img}></ReportsHeader>
                    { this.state.error !== null?<div className="alert alert-danger">
                            { error }
                    </div>:
                    <section className="fluid_center">
                            <div className="container-fluid container-fluid-no-padding">
                                <div className="row no-gutters top_list_menubar">
                                    <div className="col-6"><button className="btn btn-secondary btn-sm d-flex align-items-center" type="button" onClick={ e => {
                                                this.props.setUrl('/reports/'+this.props.categoryId);
                                            }}>
                                        <img src="../assets/img/Image%2029.png" /> lista nézet
                                        </button>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <button className="btn btn-primary btn-sm text-center d-flex align-items-center" type="button" onClick={ e => {
                                                this.props.setUrl('/new/'+this.props.categoryId);
                                            }}>
                                            <img src={"../assets" + this.state.typeCategory.img} style={{ filter: "invert(100%)" }} /> új felvitele
                                            </button>
                                        </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12">
                                       <Maps
                                            key={'map_list' }
                                            zoom={ this.state.items.length>1?null:12 }
                                            api={this.apiKey}
                                            mapId="home_map"
                                            width="100%"
                                            height={ this.state.mapHeight+ "px"}
                                            mapTypeControl = {false}
                                            mapStyle="default"
                                            icons = {[
                                                {
                                                    name: 'Info',
                                                    url: '../assets/img/picker.png'
                                                }
                                            ]}
                                            markers = { markers }
                                            markerClickHandler={ (marker:any, infowindow:any, markerIcon:any) => {
                                                let popover = infowindow.setContent(
                                                    '<strong>' + marker.typeName + '</strong>'
                                                    + '<a id="map_btn_'+marker.id+'" href="#" class="map_btn_eye"><i class="icon ion-eye"></i></a>'
                                                    + '<br /><br />' + marker.title
                                                );
                                                infowindow.open((window as any).map, markerIcon);
                                                setTimeout(() => {
                                                    document.getElementById('map_btn_'+marker.id).addEventListener('click', (e) => {
                                                        e.preventDefault();
                                                        this.props.setUrl('/view/'+marker.id);
                                                    });
                                                }, 400);
                                            } }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                     }
                    <Footer setUrl={this.props.setUrl}></Footer>
               </>:'Betöltés...' }


			</>
        );
    }
    getMapHeight() {
        setTimeout(() => {
            if ( this.state.mapHeight === 400 && document.querySelector('.fluid_center') !== null ) {
                    this.setState({
                        mapHeight: (document.querySelector('.fluid_center') as HTMLDivElement).offsetHeight + 20
                    });
            }
        }, 200);
    }
}
