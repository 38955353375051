
import * as React from "react";
import { ITypeCategory } from "../interfaces/IType";
import { Api } from "../lib/Api";
import ReportsHeader from "./ReportsHeader";
import Footer from "./Footer";
import ReportsFooter from "./ReportsFooter";

interface INewReportDashboardProps {
    setUrl:any
    typeCategoryId:number
}

interface INewReportDashboardState extends IgetTypeCategoryResult {
    loading     : boolean
}
export interface IgetTypeCategoryResult{
    typeCategory: ITypeCategory
    error:string
}

export default class NewReportDashboard extends React.Component<INewReportDashboardProps, INewReportDashboardState>{

  	constructor(props:INewReportDashboardProps){
        super(props);
        this.state = {
            typeCategory : null,
            loading      : true,
            error        : null
        }
        this.init();
    }

    init() {
        Api.call('/getTypeCategory', { typeCategoryId: this.props.typeCategoryId}, (result:IgetTypeCategoryResult)=>{
            this.setState({
                typeCategory   : result.typeCategory,
                error          : result.error!==undefined?result.error:null,
                loading        : false
            });
        });
    }

    render() {
        document.body.classList.remove('hompe_page');
        return (
             <>
               { this.state.loading === false?<>
                    <ReportsHeader setUrl={this.props.setUrl} categoryId={this.props.typeCategoryId} name={this.state.typeCategory.name} img={this.state.typeCategory.img}></ReportsHeader>
                    { this.state.error !== null?<div className="alert alert-danger">
                            { this.state.error }
                    </div>:
                        <section className="d-flex align-items-center fluid_center" id="create_dashboard">
                            <div className="container">
                                <section id="create_dasbhoard_buttons">
                                    <div className="row">
                                        <div className="col" style={{marginBottom: '15px'}}>
                                            <button className="btn btn-primary btn-block btn-lg d-flex justify-content-center align-items-center" type="button" id="create_with_image_btn" onClick={ e => this.props.setUrl('/new-with-image/'+this.props.typeCategoryId) }>
                                                <i className="icon ion-camera"></i> fotó készítése
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-secondary btn-block btn-lg d-flex justify-content-center align-items-center" type="button" id="create_without_image_btn" onClick={ e => this.props.setUrl('/new-without-image/'+this.props.typeCategoryId) }>
                                                rögzítés fotó nélkül
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                     }
                   <ReportsFooter setUrl={this.props.setUrl}  categoryId={this.state.typeCategory.id} name={this.state.typeCategory.name}></ReportsFooter>
               </>:'Betöltés...' }
			</>
        );
    }
}
